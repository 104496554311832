@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500');
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-Black.otf');
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-Bold.otf');
	font-weight: bold;
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-ExtraBold.otf');
	font-weight: 900;
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-Hairline.otf');
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-Light.otf');
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-Regular.otf');
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-SemiBold.otf');
}
@font-face {
	font-family: "Montserrat";
	src: url('/fonts/Montserrat-UltraLight.otf');
}

*{
	font-family: "Montserrat";
	color: #000;
}
body{
    padding-top: 115px;
}
.h50{
	font-size: 3.5714285714285716em;
}
.h1, h1{
	/*Semibold 36px*/
	font-weight: 600;
	font-size: 2.2857142857142856em
}
.h2, h2 {
	/*Semibold 30px*/
	font-weight: 600;
	font-size: 2.142857142857143em
}
.h3, h3 {
	/*Semibold 24px*/
	font-weight: 600;
	font-size: 1.7142857142857142em
}
.h4, h4 {
	/*Semibold 18px*/
	font-weight: 600;
	font-size: 1.2857142857142858em;
}
.h5, h5 {
	/*Semibold 14px	*/
	font-weight: 600;
	font-size: 1.1428571428571428em;
}
.h6, h6 {
	/*Semibold 12px*/
	font-weight: 600;
	font-size: 0.8571428571428571em
}
#header-language-social{
	background-color: #E7E7E7;
	padding-top: 13px;
    padding-bottom: 2px;
}
.lang{
	padding-left: 0px;
}
.lang li{
	display: inline;
	padding-right: 15px;
}
.lang li a{
	text-transform: uppercase;
	color: #555555;
	font-weight: bold;
}
.lang li a.active{
	color: #000;
}
.lang li a:hover{
	color: #000;
	text-decoration: none;
}
.img-full-width{
	width: 100%;
}
.img-full-height{
	height: 100%;
}
.text-green{
	color: #B7D000;
}
.text-green-2{
	color: #00913d;
}
.text-white{
	color: #FFF !important;
}
.text-white p{
	color: #FFF !important;
}
.cabecera-cormoran p {
	color: #FFF;
	text-align: center;
	line-height: 26px;
}
.text-bold{
	font-weight: bold;
}
.text-bolder{
	font-weight: bolder;
}
.text-semi-bold{
	font-weight: 600;
}
.text-regular{
	font-weight: 500;
}
.text-light{
	font-weight: 300;
}
.text-medium{
	font-weight: 500;
}
.text-red{
	color: #e4271b
}
.text-gray{
	color: #555555;
}
.text-gray-2{
	color: #d8d8d8;
}
.social-networks{
	display: inline;
	/*padding-left: 0px;*/
	border-left: 1px solid #fff;
}
#header-language-social > div > div > div.col-xs-12.col-sm-4.pull-right.text-right > h5{
	margin-right: 30px;
}
.social-networks li{
	display: inline;
}
.social-networks li a{
	color: #555555;
	padding-right: 15px;
}
.container-cormoran{
	padding-left: 50px;
	padding-right: 50px;
}
.navbar-cormoran{
	margin-bottom: 0px;
}
.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}
#slider-home .opacity-blue{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(41, 55, 152, 0.4);
}
#slider-home .carousel-control .fa.fa-angle-right{
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 5;
    display: inline-block;
	border: 1px solid;
	color: #fff;
    width: 39px;
    font-size: 30px;
    height: 39px;
    border-radius: 30px;
    padding: 3px 0px 3px 3px;
    opacity: 1;
}
#slider-home .carousel-control .fa.fa-angle-left{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    font-size: 30px;
    display: inline-block;
	border: 1px solid;
	color: #fff;
    width: 39px;
    height: 39px;
    border-radius: 30px;
    padding: 3px 3px 3px 0px;
    opacity: 1;
}

.navbar-cormoran{
    background-color: #F8F9FD;
    height: 70px;
    margin-top: 45px;
}
.navbar-cormoran .nav>li>a{
	color: #555555;
    font-weight: bold;
	border-top:8px solid #F8F9FD;
}
.navbar-cormoran .nav>li>a:focus, .navbar-cormoran .nav>li>a:hover {
	color: #000;
    text-decoration: none;
    background-color: #E7E7E7;
	border-top:8px solid #e4271b;
}
.navbar-cormoran .nav>li.active>a {
	color: #000;
    text-decoration: none;
    background-color: #E7E7E7;
	border-top:8px solid #e4271b;
}
#slider-home .carousel-control.left, #slider-home .carousel-control.right{
	background-image: -webkit-linear-gradient(left,rgba(0,0,0,0) 0,rgba(0,0,0,.0) 100%);
}
#slider-home .carousel-control.left:hover, #slider-home .carousel-control.right:hover{
	background-image: -webkit-linear-gradient(left,rgba(0,0,0,0) 0,rgba(0,0,0,0) 100%);
}
#slider-home .carousel-caption{
	top:130px;
}
#slider-home .carousel-caption a.btn{
	margin-top: 80px;
}
#slider-home .carousel-caption div{
	margin-top: 50px;
	margin-bottom: 60px;
}
.btn{
	font-size: 0.8571428571428571em
}
.btn-red{
	background-color: #e4271b;
	color: #fff;
	border:1px solid #fff;
	border-radius:0px;
	padding:5px 20px;
	-webkit-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-o-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
}
#map{
	height: 800px;
}
#footer-top .btn-red{
	padding:5px 20px;
}

.btn-red:hover{
	background-color: #B7CE01;
	color: #524E42;
	border:1px solid #524E42;
}
.btn-gray{
	background-color: #d8d8d8;
	color: #555555;
	border-radius:0px;
	padding:10px 25px;
	-webkit-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-o-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
}
.btn-gray:hover{
	background-color: #555555;
	color: #d8d8d8;
	outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}
.btn-small {
	padding:3px 20px;
}
.btn-100{
	width: 100px;
}
#excursiones{
	padding-top: 50px;
	padding-bottom: 50px;
}
.inline{
	display: inline;
}
#slider-excursiones
{
	height: 300px;
}

#slider-excursiones .carousel-inner>.item.active, .carousel-inner>.item.next.left, .carousel-inner>.item.prev.right {
    height: 100%;
}

#slider-excursiones .carousel-control.left, #slider-excursiones .carousel-control.right{
	background-image: -webkit-linear-gradient(left,rgba(0,0,0,0) 0,rgba(0,0,0,.0) 100%);
}
#slider-excursiones .carousel-control.left:hover, #slider-excursiones .carousel-control.right:hover{
	background-image: -webkit-linear-gradient(left,rgba(0,0,0,0) 0,rgba(0,0,0,0) 100%);
}
#slider-excursiones .carousel-control{
	opacity: 1;
}
#slider-excursiones .carousel-control .fa.fa-angle-right{
    position: absolute;
    background-color: #000;
    top: 50%;
    right: 44%;
    z-index: 5;
    display: inline-block;
	border: 8px solid;
	color: #fff;
    width: 50px;
    font-size: 18px;
    height: 50px;
    border-radius: 30px;
    padding: 8px 0px 3px 3px;
    opacity: 1 !important;
}
#slider-excursiones .carousel-control .fa.fa-angle-left{
    position: absolute;
    background-color: #000;
    top: 50%;
    left: 44%;
    z-index: 5;
    font-size: 18px;
    display: inline-block;
	border: 8px solid;
	color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    padding: 8px 3px 3px 0px;
    opacity: 1 !important;
}
.opacity-slider-excursiones {
  border-radius: 5px;
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  margin-left: 15px;
  width: calc(100% - 30px);
  height: 100%;
}
.content-slider{
    position: absolute;
    top: 45px;
    left: 50px;
    width: calc(100% - 60px - 30px);
    text-align: left;
    height: 100%;
}
.slider-star{
	margin-top: 20px;
}
.slider-star i{
	color: #f8ea1b;
	font-size: 1.5em;
}
#incentivos{
	background-image: url(/img/iStock_17230570_XXLARGE.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#incentivos .container-cormoran{
	z-index: 1;
    position: relative;
}
#incentivos .opacity-incentivos {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#incentivos h1, #incentivos p{
	margin-bottom: 45px;
}
#video{
	padding-top: 100px;
	padding-bottom: 100px;
}
#video iframe{
	width: 100%;
	height: 350px;
}
#footer-top{
	padding-top: 100px;
	padding-bottom: 50px;
	background-color: #e7e7e7;
}
#footer-bottom{
	border-top: 2px solid #B7D000;
/*#00913d;*/
	background-color: #555555;
	padding-top: 25px;
	padding-bottom: 40px;

}
.tag-footer{
  border-width: 1px;
  border-color: rgb( 228, 39, 27 );
  border-style: solid;
  border-radius: 5px;
  padding:5px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #000;

}
.tag-footer:hover, .tag-footer:focus, .tag-footer:active{
	background-color: #B7CE01;
	color: #524E42;
	border:1px solid #524E42;
	text-decoration: none;
}
.form-cormora{
	border: 0px;
	border-bottom: 1px solid #555555;
	border-radius: 0px;
	width: 100%;
	background-color: transparent;
	padding-bottom: 10px;
	margin-bottom: 50px;
}
.titulo-footer, .img-logo-footer{
	margin-bottom: 50px;

}
.img-logo-footer{
	max-width: 240px;

}
.video-img{
	margin-bottom: 40px;
	cursor: pointer;
}
.play-youtube{
	    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 50px);
}
#excursiones-header{
	background-image: url(/img/iStock_000043884176_Medium.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#excursiones-header .container-cormoran{
	z-index: 1;
    position: relative;
}
#excursiones-header .opacity-excursiones-header {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#excursiones-header h1, #excursiones-header p{
	margin-bottom: 45px;
}
.line-height-14{
    line-height: 14px;
}
.line-height-26{
    line-height: 26px;
}
.line-height-30{
    line-height: 30px;
}
#excursiones-all{
	padding-top: 60px;
	padding-bottom:  60px;
}

.img-card-excursion{
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	height: 234px;
	position: relative;
	border-radius: 5px 5px 0 0;
}
.img-logo-excursion{
	position: absolute;
	bottom: 15px;
	left: 15px;
}
.card-excursion{
	 border-radius: 5px;
	 margin: 15px;
	 padding-bottom:  15px;
     box-shadow: 1px 1.732px 5px 0px #676767;
	-webkit-transition: all 300ms ease-in;
	-moz-transition: all 100ms ease-in;
	-ms-transition: all 100ms ease-in;
	-o-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
}

.slider-card-excursion{
	 border-radius: 5px;
	 cursor: pointer;
 	  /*box-shadow: 2px 2.732px 5px 0px #676767;*/
	-webkit-transition: all 300ms ease-in;
	-moz-transition: all 100ms ease-in;
	-ms-transition: all 100ms ease-in;
	-o-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 100%;
	border-width: 5px;
	border-color: white;
	border-style: solid;
}

.content-excursiones{
	padding: 15px;
}
.content-text-excurcion{
 height: 40px;
}
.content-excursiones .buttons-excursiones .btn-gray{
	margin-left: 25px;
	margin-right: 25px;
}
#testimonials{
	background-image: url(/img/excursion-testimonials.png);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#testimonials .container-cormoran{
	z-index: 1;
    position: relative;
}
#testimonials .opacity-testimonials {
  background-color: rgb( 0, 52, 114 );
  opacity: 0.5;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#slider-testimonials .carousel-inner{
	height: 400px;
}
#slider-testimonials .carousel-inner .carousel-caption{
	bottom: auto;
}
#slider-testimonials .carousel-inner .carousel-caption h4{
	margin-top: 50px;
	margin-bottom: 50px;
}
#slider-testimonials .carousel-inner .carousel-caption h5{
	margin-top: 50px;
}
#slider-testimonials .carousel-indicators .active {
    background-color: #fff;
    background-color: transparent;
    border-width: 3px;
    width: 20px;
    height: 20px;
}
#slider-testimonials .carousel-indicators  li{
    width: 15px;
    height: 15px;
    border-color: #e4271b;
    border-width: 1px;
}
#slider-testimonials .carousel-inner .carousel-caption hr{
  font: normal normal normal 14px/1 FontAwesome; /* choose the font you like */
  text-align: center; /* horizontal centering */
  line-height: 1px; /* vertical centering */
  font-size: 2em; /* choose font size you like */

  border-color: #fff;
  margin: 20px 10px; /* 20px space above/below, 10px left/right */

  /* ensure 1px gap between borders */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}

#slider-testimonials .carousel-inner .carousel-caption hr:first-child:after {
  content: "\f10e"; /* section sign */
  color: #fff;
  display: inline; /* for vertical centering and background knockout */
  background-color: transparent; /* same as background color */
  padding: 0 0.5em; /* size of background color knockout */
}

/* opera doesn't render correctly. hide section sign */
x:-o-prefocus, #slider-testimonials .carousel-inner .carousel-caption hr:first-child:after {
  content: "";
}
.qq {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 0px;
}
.qq i{
   width: 30px;
    height: 30px;
    margin-top: -15px;
}
.qq:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 45%;
    height: 1px;
    background: #fff;
}
.qq:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 45%;
    height: 1px;
    background: #fff;
}
#header-single-excursion{
	background-color: #f5f5f5;
}
.content-single-excursion{
	padding-top: 45px;
	padding-bottom: 45px;
	background-color: #f5f5f5;
}
.content-single-excursion-2{
/*	background-image: url(/img/tropical001.jpg);
	background-position: center;
	background-size: cover;*/
	text-align: center;
	position: relative;
	height: 100%;
}
.opacity-slider-silgle-excursion{
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

}
.content-slider-single{
	padding-right: 20%;
	padding-left: 20%;
}
.content-single-excursion{
	padding-top: 45px;
	padding-left: 80px;
	padding-bottom: 45px;
	background-color: #f5f5f5;
}
#slider-single-excursiones .carousel-indicators .active {
    background-color: #fff;
    background-color: transparent;
    border-width: 3px;
    width: 20px;
    height: 20px;
}
#slider-single-excursiones .carousel-indicators  li{
    width: 15px;
    height: 15px;
    border-color: #e4271b;
    border-width: 1px;
}
.no-m-r{
	margin-right: 0px;
}
.no-p-r{
	padding-right: 0px;
}
.mapa-single{
	position: relative;
}
.mapa-text-single{
	position: absolute;
	bottom: 80px;
	left: 80px;
	width: 50%;
}
#incentives-header{
	background-image: url(/img/iStock_000089708613_Full.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#incentives-header .container-cormoran{
	z-index: 1;
    position: relative;
}
#incentives-header .opacity-incentives-header {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#incentives-header h1, #incentives-header p{
	margin-bottom: 45px;
}
#incentives-goals{
	padding-top: 60px;
	padding-bottom: 60px;
}
#informacion-incentivos{
	background-color: #f5f5f5;
}
.content-incentivos{
	padding-top: 45px;
	padding-bottom: 45px;
	padding-left: 80px;
	background-color: #f5f5f5;
}
.content-incentivos-2{
		padding-top: 45px;
	padding-bottom: 45px;
	background-image: url(/img/iStock-482489716.jpg);
	background-position: center;
	background-size: cover;
	text-align: center;
	position: relative;
	height: 700px;
}
.form-cormoran{
	margin-bottom: 30px;
	background-color: transparent;
	color: #555555;
	border-color: #000;
	border-width: 1px;
	padding: 5px 15px;
	height: 40px;
}
.fancybox-infobar__body span{
	color: #ddd;
}
#photowall-header{
	background-image: url(/img/2Horas08.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#photowall-header .container-cormoran{
	z-index: 1;
    position: relative;
}
#photowall-header .opacity-photowall-header {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#photowall-header h1, #photowall-header p{
	margin-bottom: 45px;
}

#photowall{
	padding-top: 60px;
	padding-bottom: 60px;
}
.image-photo-wall{
	cursor: pointer;
	width: 100%;
	margin-bottom: 30px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.image-photo-wall-2{
	cursor: pointer;
	width: 100%;
	margin-bottom: 15px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.image-photo-wall:hover .hover-photowall{
	display: block;
}
.image-photo-wall-2:hover .hover-photowall-2{
	display: block;
}
.hover-photowall{
	-moz-transition: all 100ms ease-in;
	-ms-transition: all 100ms ease-in;
	-o-transition: all 100ms ease-in;
	cursor: pointer;
	display: none;
	transition: all 100ms ease-in;
 	position: absolute;
    -webkit-transition: all 300ms ease-in;
    background-color: rgba(0,0,0,0.6);
    height: calc(100% - 30px);
    left: 0;
    margin: 0px 15px;
    padding-top: calc(50% - 32px);
    top: 0;
    width: calc(100% - 30px);
}
.hover-photowall-2{
	-moz-transition: all 100ms ease-in;
	-ms-transition: all 100ms ease-in;
	-o-transition: all 100ms ease-in;
	cursor: pointer;
	display: none;
	padding-top: calc(25% - 32px);
	transition: all 100ms ease-in;
 	position: absolute;
    -webkit-transition: all 300ms ease-in;
    background-color: rgba(0,0,0,0.6);
    height: calc(100% - 15px);
    left: 0;
    margin: 0px 15px;
    top: 0;
    width: calc(100% - 30px);
}
#reserva-ya-header{
	background-image: url(/img/iStock_000089708613_Full.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#reserva-ya-header .container-cormoran{
	z-index: 1;
    position: relative;
}
#reserva-ya-header .opacity-reserva-ya-header {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#reserva-ya-header h1, #reserva-ya-header h4{
	margin-bottom: 45px;
}
#pasos-reserva{
	background-color: #f5f5f5;
	padding-top: 60px;
	padding-bottom: 60px;
}
.number-steps-active{
	height: 40px;
    width: 40px;
    margin: 0 auto;
    display: block;
    border-radius: 50px;
    padding-top: 2px;
    background: #e4271b;
}
.number-steps{
	height: 40px;
    width: 40px;
    margin: 0 auto;
    display: block;
    border-radius: 50px;
    padding-top: 2px;
    background: #d8d8d8;
}
.form-cormoran-2{
	background-color: #d8d8d8;
	margin-bottom: 30px;
	border-right: 0px;
	-webkit-box-shadow: none;
    box-shadow:none;
}
.input-group.form-cormoran-group{
	margin-bottom: 30px;
}
.content-pasos{
	margin-top: 70px;
}

#contacto-header{
	background-image: url(/img/Terraza-Jumbo.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 80px;
	padding-bottom: 30px;
}
#contacto-header .container-cormoran{
	z-index: 1;
    position: relative;
}
#contacto-header .opacity-contacto-header {
  background-color: rgb( 35, 50, 89 );
  opacity: 0.6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
#contacto-header h1, #contacto-header p{
	margin-bottom: 45px;
}

#informacion-contacto{
	background-color: #f5f5f5;
}
.content-contacto{
	padding-top: 45px;
	padding-bottom: 45px;
	padding-left: 80px;
	background-color: #f5f5f5;
}
.content-contacto-2{
		padding-top: 45px;
	padding-bottom: 45px;
	background-image: url(/img/tropical001.jpg);
	background-position: center;
	background-size: cover;
	text-align: center;
	position: relative;
	height: 700px;
}
.datos-contacto{
	padding: 60px 0 80px 0;
}
.tel-link{
	color: initial;
}
.tel-link:hover,.tel-link:focus,.tel-link:active{
	text-decoration: none;
	color: initial;
}
#footer-top .border-foot-vertical{
	border-left: 1px solid #000;
	height: 100%;
	padding: 0px 30px;
}
#footer-top .border-foot-vertical:first-child{
	border-left: 0px solid #000;
}
.content-single-excursion-2 .item {
	    background-position: center;
	    background-size: cover;
	    background-repeat: no-repeat;
}
@media (min-width: 768px)
{
	#slider-excursiones .carousel-inner {
	    height: 300px;
	}
	.navbar-cormoran .navbar-nav>li>a {
    	padding-top: 19px;
    	padding-bottom: 23px;
	}
	.navbar-cormoran .navbar-brand {
   		 padding-left: 0;
	}
	#slider-excursiones  .carousel-caption {
     right: 8%;
     left: 8%;
    padding-bottom: 30px;
	}
}
@media (min-width: 992px)
{
	.content-single-excursion-2 .carousel-caption {
	    right: 20%;
	    left: 20%;
	    padding-bottom: 50px;
	}
	#slider-excursiones .carousel-inner {
	    height: 450px;
	}
}
@media (min-width: 1300px)
{
	.container-cormoran{
	    padding-left: 80px;
    	padding-right: 80px;
	}
	#slider-excursiones .carousel-inner {
	    height: 500px;
	}

}
@media (min-width: 1440px)
{
	.img-card-excursion{
		height: 350px;
	}
	#slider-excursiones .carousel-inner {
	    height: 600px;
	}
}
@media (min-width: 1600px)
{
	#slider-excursiones .carousel-inner {
	    height: 700px;
	}
}
@media (max-width: 1200px)
{
		.navbar-brand>img {
		  width: 300px;
		}
		#slider-home .carousel-caption {
		    top: 0px;
		}
		#slider-home .carousel-caption {
		    left: 10%;
		    right: 10%;
		}
		.content-slider {
		    position: absolute;
		    top: 15px;
		    left: 45px;
		    width: calc(100% - 60px - 30px);
		    text-align: left;
		}
		.content-slider > h5{
			font-size: 14px;
		}
		.content-slider > h5{
			font-size: 14px;
		}
}
@media (max-width: 1024px)
{
		.content-slider {
		    top: 5px;
		}
		.navbar-brand>img {
		  width: 200px;
		}
		.content-slider .slider-star {
		    margin-top: 5px;
		}
}
@media (max-width: 991px){
	#carousel-example-generic-1-2 .opacity-slider-excursiones {
   	    border-radius: 5px;
     	background-color: rgb( 35, 50, 89 );
   	 	opacity: 0.6;
  	  	position: absolute;
 	  	left: -15px;
 	   	top: 20px;
	    width: calc(100%);
	    height: calc(100% - 50px);
	}
	#carousel-example-generic-1-2 .content-slider {
  	  top: 45px;
	}
	.content-incentivos, .content-contacto {
	    padding:30px;
	}
	#slider-excursiones .carousel-inner {
  	    height: 1020px;
	}
	#slider-home .carousel-caption {
	    left: 5%;
	    right: 5%;
	}
	.container-cormoran{
	    padding-left: 30px;
    	padding-right: 30px;
	}
	#slider-home .carousel-caption a.btn {
  	  margin-top: 15px;
	}
	#slider-home .carousel-caption .h50 {
  	  font-size: 20px;
	}
	.nav>li>a {
	    padding: 10px 5px;
	}
}
@media (max-width: 768px)
{
	body{
		padding-top: 135px;
	}
	.navbar-cormoran{
	    margin-top: 65px;
	}
	#carousel-example-generic-1-2 .opacity-slider-excursiones {
        top: 20px;
        height: calc(100% - 40px);
    }
    #carousel-example-generic-1-2  .carousel-caption {
        right: 10%;
        left: 10%
	}
	#slider-excursiones .carousel-inner {
   	    height: 500px;
	}
	#slider-home .carousel-caption h4 {
  	    font-size: 14px;
	}
	#slider-home .carousel-caption div {
    	margin-top: 15px;
    	margin-bottom: 15px;
	}
	.container-cormoran{
	    padding-left: 15px;
    	padding-right: 15px;
	}
	.navbar-collapse.in {
	    overflow-y: auto;
	    z-index: 11;
	    position: absolute;
	    width: 100%;
	    text-align: center;
	    left: 0;
    	top: 60px;
	}
	.navbar-toggle {
	    padding-right: 0px;
	    margin-right: 0px;
	}
	.navbar-cormoran .nav>li>a {
	    color: #555555;
	    background-color:  #F8F9FD;
	    font-weight: bold;
	    border-top: 8px solid #F8F9FD;
	}
	.navbar-brand>img {
	    width: 222px;
	    margin-top: 10px;
	}
}
@media (max-width: 600px){
	#carousel-example-generic-1-2 .carousel-caption {
	    right: 13%;
	    left: 13%;
	}
	#slider-excursiones .carousel-inner {
   	    height: 620px;
	}
	.mapa-text-single {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 80%;
}
}
@media (max-width: 440px){
	#carousel-example-generic-1-2 .carousel-caption {
	    right: 13%;
	    left: 13%;
	}
	#slider-excursiones .carousel-inner {
   	    height: 500px;
	}
	#slider-testimonials .carousel-inner {
    	height: 615px;
	}
}
@media (max-width: 375px){
	#carousel-example-generic-1-2 .carousel-caption .img-icon-slider{
	    height: 45px;
	}
	#carousel-example-generic-1-2 .carousel-caption {
	    right: 10%;
	    left: 10%;
	}
	#slider-excursiones .carousel-inner {
   	    height: 500px;
	}
	#carousel-example-generic-1-2 .content-slider {
   	 top: 35px;
	}
	#slider-excursiones .carousel-inner {
    height: 439px;
	}
}

#loading-ajax {
	display: none;
	position: fixed;
	z-index: 5000;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
}

#loading-ajax > i {
	position: fixed;
	top: 50%;
	left: 48%;
	font-size: 45px;
	color: #ffffff;
}

div.errors {
  width: 100%;
  background-color: #E4271B;
}

div.errors p {
  color: #DDD;
  text-align: center;
  font-size: 2em;
}

.text-right
{
	text-align: right !important;
}


pre.errors {
  display: none;
}

.ocultar-primero {
  display: none;
  background-color: #B5CB0E;
}

.deshabilitar-cupon {
  display: none;
}

.stripe-button-el {
    /* Debe ser !important para prevalecer sobre el script que añade el botón */
    margin: 0 20px 0 20px !important;
}
